
body {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  /* padding-left: 2rem;
  padding-right: 2rem; */
  line-height: 2;
  -ms-overflow-style: none; /* IE and Edge */
  height: 100%;
}

@-moz-document url-prefix() { /* Disable scrollbar Firefox */
  html{
    scrollbar-width: none;
  }
}
body {
  margin: 0; /* remove default margin */
  scrollbar-width: none; /* Also needed to disable scrollbar Firefox */
  -ms-overflow-style: none;  /* Disable scrollbar IE 10+ */
  overflow-y: scroll;
}
body::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* Disable scrollbar Chrome/Safari/Webkit */
}

body::before {
  content: '';
  position: fixed;
  top: 0;
  right: 0;
  width: 0.5rem; /* Adjust as needed */
  height: 100%;
  background-color: transparent; /* Background color of the hidden scrollbar space */
}

/* Webkit browsers */
body::-webkit-scrollbar {
  width: 0.5rem; /* Set a width for the invisible scrollbar space */
}

.content-wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
}

.text-web-italic {
  font-style: italic;
  color: #212121;
}

.text-web-bold {
  font-weight: 900;
}

.text-web {
  font-weight: 400;
  color: #212121;
  text-align: justify;
}

.navlink {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400 !important;
  font-style: italic;
  font-size: 0.8rem;
  color: #6e6e73;
}

.link-in-text {
  text-decoration: none;
  color:#0e0e0e;
  background-color: #bfc500;
  border-radius: 15px;
  padding: 3px;
  transition: color 0.5s;
}

.link-in-text:hover {
  color: #fff;
}

/* Navbar section */

.navbar-wrapper {
  padding-left: 5%;
  padding-right: 5%;
}

.footer-wrapper {
  padding-left: 5%;
  padding-right: 5%;
}


.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff; /* Change the background color as needed */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* Make sure the mobile menu appears above other elements */
}

.mobile-menu-link {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400 !important;
  font-style: italic;
  font-size: 0.8rem;
  margin: 10px 0;
  text-decoration: none;
  color: #212121; /* Change the text color as needed */
}

/* Styling for the menu icon and close icon */
.menu-icon-container {
  display: flex; /* Show the menu icon on mobile screens */
  align-items: center;
  justify-content: center;
  position: relative; /* Make the container relatively positioned */
  z-index: 10000; /* Ensure the icons appear above other elements */
}

.close-icon {
  position: absolute; /* Position the close icon absolutely */
  top: 50%; /* Center the icon vertically */
  left: 50%; /* Center the icon horizontally */
  transform: translate(-50%, -50%); /* Center the icon perfectly */
  cursor: pointer; /* Show pointer cursor on hover */
}

.navlink:hover {
  color: #bfc500 !important;
  /* Additional styling for the link when hovering can be added here */
}

/* End of the Navbar section */

p {
  margin-top: 1rem;
}

.pID {
  margin-top: 1rem;
  margin-bottom: 0;
}

.mint-form {
  display: flex;
  align-items: center; /* Align items vertically */
  justify-content: center; /* Align items horizontally */
  width: 100%;
}

.css-1f7jt3g-MuiFormControl-root-MuiTextField-root.css-1f7jt3g-MuiFormControl-root-MuiTextField-root {
  margin: 0px;
}

.css-1hcd1vj.css-1hcd1vj {
  width: 100%;
  margin: 0px;
}

.on-chain-button {
  margin-bottom: 1rem !important;
}

.hero-video {
  position: relative; /* Set the parent div to a positioned element */
  overflow: visible;
  height: auto;
  width: 100%;
  background-color: #a5abb1;
  text-shadow: 0 0 20px , 0 0 40px, 0 0 80px , 0 0 100px;
}

.manifesto-video {
  position: relative; /* Set the parent div to a positioned element */
  overflow: visible;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  max-width: 640px;
  background-color: #a5abb1;
  text-shadow: 0 0 20px , 0 0 40px, 0 0 80px , 0 0 100px;
}

.dropdown-menu {
  font-style: italic;
  font-weight: 100;
}

.text-separator {
  margin-bottom: 1rem;
}

.titles {
  color: #212121;
  font-weight: 900;
  font-style: italic;
}

.App {
  padding-top: 0.4rem;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  /* To avoid content flickering while page is loading */
  min-height: 103vh;
  /* min-height: 70vh; */
}

#OxLogo {
  margin-right: auto;
  width: 25rem;
  height: auto;
}

.loading-container {
  display: flex;
  justify-content: center; 
  align-items: center; 
  height: 100%; 
}

#onchainLoading {
  padding-left: auto;
  padding-right: auto;
}

.menu-icon-container {
  margin-left: auto;
}

.breadcrumbs-container {
  margin-left: auto;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
  /* Add hover color */
  transition: color 0.3s;
}

.App-link:hover {
  color: #ea34b0;
}

.imageGallery {
  width: 192;
}

.image-container {
  width: 100%;
}

.image {
  width: 100%;
  /* image-rendering: pixelated; */
  display: block; /* Ensure proper spacing around the image */
}

.caption {
  font-style: italic;
  font-size: 0.8rem;
  font-weight: 300;
}

.attCaption {
  font-style: italic;
  font-size: 0.8rem;
  font-weight: 300;
}

.attention-container {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 0;
  margin-right: 0;
  display: flex;
  align-items: center; /* Vertically align the SVG and figcaption */
  gap: 6px; /* Add some spacing between SVG and figcaption */
}

.svg-container {
  position: relative;
  width: 29px; /* Adjust the width of the SVG container as needed */
}

.no-rounded-border .MuiFilledInput-root {

  border-radius: 0;
}

/* Add Punk or Add Attributes succsess message */


.displayMessage {
  display: flex;
  justify-content: center;
  text-align: center;
}

.return-attributes-count {
  display: flex;
  font-style: italic;
  color:#bfc500;
  justify-content: center;
}

.RenderPunk {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust the height if needed */
}

#punkAttributes {
  font-weight: 400;
  font-style: italic;
  width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top:1rem;
}

.separate-line {
  height: 2px;
  width: 100%;
  background-color: rgba(110, 110, 115, 0.2);
}

/*
  Gallery section
*/

.search-bar{
  display: flex;
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 1rem;
}

.search-input {
  flex: 4;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.search-button {
  flex: 1;
  padding-bottom: 1rem;
  padding-top: 1rem;

}

.reset-button {
  flex: 1;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.gallery-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 960px;
  margin: auto;
}

.image-item img{
  width: 240px; /* Set the width of each image */
  height: 240px; /* Set the height of each image */
  margin-right: 0.5rem; /* Add some horizontal spacing between images */
  margin-bottom: 0.5rem; /* Add some vertical spacing between rows */
  box-sizing: border-box; /* Include padding and border in width/height */
}




.pagination-buttons {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/*
  End of Gallery section
*/

/*
  Footer section
*/

.pFooter {
  margin-bottom: 0px;
}

#metamaskAddress {
  text-align: center;
}

#metamaskAddressMobile{
  text-align: center;
  display: none;
}

#textCenter {
  text-align: center;
}

.footer {
  padding-top: calc(1rem + 1px); /* Add 1px to match the navbar padding */
  padding-bottom: calc(1rem + 1px); /* Add 1px to match the navbar padding */
}

.footer-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.RDLogo {
  height: 2rem;
  margin-right: auto;
  fill: #a5abb1; /* Set the default color */
}

.footer-social-icons {
  font-size: 0.7rem;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  text-align: right;
}

#icons {
  display: flex;
  align-items: center;
}


.footer-social-icons a {
  margin-left: 1rem;
  color: #a5abb1;
  transition: 0.5s;
}

.footer-social-icons a svg {
  width: 1rem; /* Set the width of the icons */
  height: 1rem; /* Set the height of the icons */
}

.footer-social-icons a:hover {
  color: #bfc500;
}

.footer-social-icons p {
  margin-top: 1rem; /* Add margin-top to create spacing between icons and text */
  color: #a5abb1;;
}

#tos {
  cursor: pointer;
  text-decoration: underline;
  display: flex;
  align-items: center;
}

/*
  End of the Footer section
*/

/*
  Media queries
*/

@media (max-width: 912px) {
  .breadcrumbs-container {
    display: none;
  }

  #OxLogo {
    width: 15rem;
    height: auto;
  }
  .content-wrapper {
    margin-left: 0.1rem;
    margin-right: 0.1rem;
  }
  .titles {
    font-size: 27px;
  }
  #metamaskAddress {
    display: none;
  }
  #metamaskAddressMobile {
    display: block;
  }
}

@media (min-width: 912px) {
  /* Hide the mobile menu on non-mobile screens */
  .mobile-menu {
    display: none;
  }

  /* Show the menu icon on non-mobile screens */
  .menu-icon-container {
    display: none;
  }
}

@media (max-width: 1244px) {
  .content-wrapper {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}


/* @media (max-width: 600px) {
.css-r6ewbb-MuiToolbar-root {
    min-height: 22px !important;
}
} */


/* CRT effects starts here */

@keyframes flicker {
  0% {
    opacity: 0.27861;
  }
  5% {
    opacity: 0.34769;
  }
  10% {
    opacity: 0.23604;
  }
  15% {
    opacity: 0.90626;
  }
  20% {
    opacity: 0.18128;
  }
  25% {
    opacity: 0.83891;
  }
  30% {
    opacity: 0.65583;
  }
  35% {
    opacity: 0.67807;
  }
  40% {
    opacity: 0.26559;
  }
  45% {
    opacity: 0.84693;
  }
  50% {
    opacity: 0.96019;
  }
  55% {
    opacity: 0.08594;
  }
  60% {
    opacity: 0.20313;
  }
  65% {
    opacity: 0.71988;
  }
  70% {
    opacity: 0.53455;
  }
  75% {
    opacity: 0.37288;
  }
  80% {
    opacity: 0.71428;
  }
  85% {
    opacity: 0.70419;
  }
  90% {
    opacity: 0.7003;
  }
  95% {
    opacity: 0.36108;
  }
  100% {
    opacity: 0.24387;
  }
}
@keyframes textShadow {
  0% {
    text-shadow: 0.4389924193300864px 0 1px rgba(0,30,255,0.5), -0.4389924193300864px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  5% {
    text-shadow: 2.7928974010788217px 0 1px rgba(0,30,255,0.5), -2.7928974010788217px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  10% {
    text-shadow: 0.02956275843481219px 0 1px rgba(0,30,255,0.5), -0.02956275843481219px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  15% {
    text-shadow: 0.40218538552878136px 0 1px rgba(0,30,255,0.5), -0.40218538552878136px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  20% {
    text-shadow: 3.4794037899852017px 0 1px rgba(0,30,255,0.5), -3.4794037899852017px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  25% {
    text-shadow: 1.6125630401149584px 0 1px rgba(0,30,255,0.5), -1.6125630401149584px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  30% {
    text-shadow: 0.7015590085143956px 0 1px rgba(0,30,255,0.5), -0.7015590085143956px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  35% {
    text-shadow: 3.896914047650351px 0 1px rgba(0,30,255,0.5), -3.896914047650351px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  40% {
    text-shadow: 3.870905614848819px 0 1px rgba(0,30,255,0.5), -3.870905614848819px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  45% {
    text-shadow: 2.231056963361899px 0 1px rgba(0,30,255,0.5), -2.231056963361899px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  50% {
    text-shadow: 0.08084290417898504px 0 1px rgba(0,30,255,0.5), -0.08084290417898504px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  55% {
    text-shadow: 2.3758461067427543px 0 1px rgba(0,30,255,0.5), -2.3758461067427543px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  60% {
    text-shadow: 2.202193051050636px 0 1px rgba(0,30,255,0.5), -2.202193051050636px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  65% {
    text-shadow: 2.8638780614874975px 0 1px rgba(0,30,255,0.5), -2.8638780614874975px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  70% {
    text-shadow: 0.48874025155497314px 0 1px rgba(0,30,255,0.5), -0.48874025155497314px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  75% {
    text-shadow: 1.8948491305757957px 0 1px rgba(0,30,255,0.5), -1.8948491305757957px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  80% {
    text-shadow: 0.0833037308038857px 0 1px rgba(0,30,255,0.5), -0.0833037308038857px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  85% {
    text-shadow: 0.09769827255241735px 0 1px rgba(0,30,255,0.5), -0.09769827255241735px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  90% {
    text-shadow: 3.443339761481782px 0 1px rgba(0,30,255,0.5), -3.443339761481782px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  95% {
    text-shadow: 2.1841838852799786px 0 1px rgba(0,30,255,0.5), -2.1841838852799786px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  100% {
    text-shadow: 2.6208764473832513px 0 1px rgba(0,30,255,0.5), -2.6208764473832513px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
}
.crt::after {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(18, 16, 16, 0.1);
  opacity: 0;
  z-index: 2;
  pointer-events: none;
  animation: flicker 0.15s infinite;
}
.crt::before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50%), linear-gradient(90deg, rgba(255, 0, 0, 0.06), rgba(0, 255, 0, 0.02), rgba(0, 0, 255, 0.06));
  z-index: 2;
  background-size: 100% 2px, 3px 100%;
  pointer-events: none;
}
.crt {
  animation: textShadow 1.6s infinite;
}


@media (max-width: 600px) {
  .crt::after {
    animation: flicker 0.15s infinite;
  }

  .crt::before {
    background-size: 70% 1px, 1.5px 70%; /* Adjusted size values */
  }
}

/* CRT effects ends here */


/* Additional styling for the punk image */
.punkImageBg {
  min-height: 384px;
  background-color: #a5abb1;
  display: flex;
  align-items: flex-end; /* Align items to the bottom */
  justify-content: center; /* Center the image horizontally */
  position: relative; /* Set relative positioning for positioning of .punkDetailsImage */
}

.punkDetailsImage {
  width: 100%;
  height: auto;
  max-height: 384px;
  max-width: 384px;
  image-rendering: pixelated;
  position: absolute; /* Set absolute positioning to stick it to the bottom */
  bottom: 0; /* Align to the bottom */
}

.donts-animation {
  text-align: left;
}


/* Emoji section */

.emojiImage {
  width: 32px;
  height: 32px;
  vertical-align: middle; /* To align the image with the text */
}

.emojiImageCoin {
  width: 30px;
  height: 30px;
  vertical-align: middle; /* To align the image with the text */
}


/* The end of the emoji section */

/* Wallet connect custom button */

.centered-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.launchMetaMask {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25ch;
  height: 5ch;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2); /* Border with 20% opacity */
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1); /* Soft shadow */
  transition: opacity 0.3s ease;
  margin-bottom: 0.5rem;
}

.launchMetaMask:hover {
  opacity: 0.5;
}

.j {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400 !important;
}

.metamaskImage {
  width: 32px;
  height: 32px;
  vertical-align: middle; /* To align the image with the text */
  margin-right: 1rem;
}

.bytecodeDemo {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  width: 80%;
  background-color: rgba(165, 171, 177, 0.3);
  border-radius: 7px;
}

.transactionTable {
  text-align: left;
  overflow-x:auto;
  min-height:0.01%
}

.table {
  width:100%;
  max-width:1000px;
  margin-left:auto;
  margin-right: auto;
  margin-top:1rem;
}

.punk-history-row-bid {
  background: #b8a7ce;
}

.punk-history-row-sold {
  background: #adc9d6;
}

.punk-history-row-offer {
  background: #d6adad;
}

.punk-history-row-transfer {
  background: #add6b8;
}

.punk-history-row-wrap {
  background: #9bbfa5;
}

.punk-history-row-claim {
  background: #add6b8;
}

.linkNoHighlight {
  text-decoration: none;
  text-align: center;
  color:#212121;
  transition: color 0.5s ;
}

.linkNoHighlight:hover {
  color:#bfc500;
}






